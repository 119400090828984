@include createGallery();
.p-product {

  // MAIN

  &__add {
    @include f(19px,$c-white,700,55px);
    padding:0 40px;
    margin-bottom:30px;
    img {
      margin-right: 10px;
      vertical-align: text-bottom;
    }
  }

  &__attributes {

  }

  &__attributes-group {
    margin-bottom:10px;
  }

  &__attributes-name {
    @include f(14px,$c-black,700);
  }

  &__attributes-value {
    @include f(14px,$c-black,400);
  }

  &__category {
    @include f(16px, $c-font);
    margin-bottom: 35px;
  }

  &__price {
    @include f(32px,$c-theme,900);
  }

  &__price-alt {
    @include f(17px,$c-black,700);
    text-decoration: line-through;
  }

  &__price-label {
    @include f(17px,$c-black,400)
  }

  &__quantity {
    @include createQuantity(130px);
    //project specific
    margin-bottom:30px;
    input {
      background-color: #fff;
    }
  }

  &__separator {
    margin-top: 20px;
  }

  &__title {
    @include f(20px, $c-black, 700, 1.4em);
  }


  @include mq($screen-md) {
    &__title {
      @include f(17px);
    }
    &__quantity {
      margin-top:30px;
      margin-bottom:10px;
    }
    &__add {
      margin-bottom:10px;
      @include f(15px)
    }
  }


  // ADDITIONAL

  &__ask {
    a {
      line-height: 98px;
      @include mq($screen-sm) {
        line-height: normal;
        margin-bottom: 10px;
        display: inline-block;
      };
    }
  }

  &__inner {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }



  &__about {
    @include f(16px);
    line-height: 1.5em;
  }

  .c-btn {
    @include mq($screen-sm) {
      margin-top: 15px;
    };
  }




  &__link {
    @include f(13px, $c-black, 700, 45px);
    @include mq($screen-xs) {
      @include f(10px);
    };
  }

  &__comments {
    margin-bottom:40px;
  }

  .c-btn {
    i, img {
      margin-right: 10px;
    }
  }
}
