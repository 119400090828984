// Grid system extension
//
// Based on Bootstrap v4.1.3

// Container widths
// Set the large containers widths.
.container {
  @media (min-width: $screen-xlg-min) {
    &--xlg,
    &--xxlg {
      max-width: $container-xlg;
    }
  }
  @media (min-width: $screen-xxlg-min) {
    &--xxlg {
      max-width: $container-xxlg;
    }
  }
}

// Columns
// Common styles for extra large grid columns.
//@include make-large-grid-columns;
