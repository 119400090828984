.c-product {
  $mod:'.c-product';

  &__image {
    margin-bottom: 15px;
    position: relative;
    img {
      width: 100%;
    }
  }

  //&__caption {
  //  position: absolute;
  //  top:15px;
  //  left:15px;
  //  display: inline-block;
  //  background: $c-theme-alt;
  //  @include f(11px,$c-white,700);
  //  padding:5px 10px;
  //}

  &__title {
    margin:15px 0;
    @include f(14px,#212121,700);
  }

  &__price {
    @include f(18px,$c-theme,700,0);
  }

  &__link {

  }

}
