.option1 {
  .top {
    @include f(14px,$c-top-font,400,0,0);
    background: $c-bg;

    &__text {
      a {
        margin-right: 10px;
      }
      img {
        margin-right: 5px;
        &:not(:first-of-type) {
          margin-left: 30px;
        }
      }

      @include mq($screen-sidr) {
        text-align: center;
        a {
          font-weight: 600;
        }
      };

    }
  }

  .header {
    padding: 10px 0;
    border-bottom: 1px solid $c-border;
    padding:40px 0;
    background: $c-white;
    @include mq($screen-sidr) {
      padding-top: 0;
      padding-bottom: 20px;
    };


    &__box {
      position: relative;
      padding-left: 35px;
      display: inline-block;
      margin-right: 15px;
      img {
        position: absolute;
        top:4px;
        //transform: translateY(-50%);
        left:0;
      }

      &-content {
        p {
          @include f(18px,$c-black,700,1em);
          margin-bottom:0;
        }
        span {
          @include f(14px,$c-bg);
          font-style: italic;
        }
        @include mq($screen-lg) {
          p {
            @include f(15px)
          }
          span {
            @include f(13px)
          }
        }
      }

      @include mq($screen-sidr) {
        min-height: 40px;
      }
    }

    &__flex {
      display: flex;
      align-items: center;
    }

    &__right {
      text-align: right;
    }

    &__account {
      @include f(15px,$c-font,0,50px);
      a {
        margin-left: 30px;
      }
    }

    &__search {
      width: 30%;
      margin-left: 5%;
    }

    &__options {
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      margin-left: 18px;

      i {
        position: relative;
        @include f(20px);
        span {
          position: absolute;
          top: -5px;
          right: -14px;
          @include f(12px,$c-black,0,0,0,"arial");
          @include size(20px);
          @include flexAlign;
          border-radius: 50%;
          background: $c-white;
        }
      }
    }

    .logo {
      line-height: 50px;
      @include mq($screen-sidr) {
        text-align: center;
        padding:20px 0;

        img {
          height: 35px;
        }
      }
      @include mq(360px) { text-align: right; };
    }

    @include mq($screen-sidr) {
      &__search {
        width: 50%;
        margin-left: 0;
      }

      &__options {
        display: flex;
        justify-content: space-around;
        width: 100%;
        @include f(25px,$c-font,700,50px);
      }

      &__flex {
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    };
    @include mq($screen-xs) {
      &__search {
        width: 50%;
      }

      &__options {
        @include f(25px,$c-font,700,30px);
      }
    };
  }

  .navigation {
    background:$c-theme-alt;
    padding:10px 0;
  }
}

.option2 {
  .header {
    border-bottom: 1px solid $c-border;
    background: $c-white;
    padding:30px;

    @include mq($screen-sidr) {
      padding-top: 0;
      padding-bottom: 0;
    };


    &__right {
      text-align: right;
    }

    &__top {
      display: flex;
      justify-content:space-between;
      padding-left:25%;
      @include mq($screen-xlg) {
        padding-left: 10%;
      }
    }

    &__bottom {
      margin-top:40px;
    }

    &__item {
      width:30%;
    }

    .logo {
      line-height: 50px;
      margin-top:20px;
      @include mq($screen-sidr) {
        text-align: center;
        margin-top:0;
        padding:20px 0;

        img {
          height: 35px;
        }
      }
      @include mq(360px) { text-align: right; };
    }

  }
}

.option3 {
  .top {
    @include f(14px,$c-top-font,400);
    padding:15px 0 10px;
    &__lang {
      a {
        $_size:40px;
        @include size($_size);
        @include f(13px,$c-black,700,$_size,uppercase);
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
        border: 1px solid $c-border;
        &:hover {
          background-color:$c-theme;
          border-color: $c-theme;
          color:$c-white;
        }
      }
    }

    &__register {
      @include f(14px,$c-font,600,50px,uppercase);
      margin-right: 15px;
    }
  }

  .header {

    margin-bottom:30px;

    &__right {
      text-align: right;
    }


    .logo {
      @include mq($screen-sidr) {
        text-align: center;

        img {
          height: 60px;
        }
      }
    }

    @include mq($screen-sidr) {
      margin-bottom:0;
      padding-top: 5px;
    }
  }
}