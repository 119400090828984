.c-video {
  cursor: pointer;

  &__overlay {
    @include size(100%);
    @include f(4em,$c-white);
    @include flexAlign();
    position: absolute;
    top:0;
    left:0;
    background: rgba($c-black,.85);
    z-index:2;
    transition:$def-transition;

    &.is-hidden {
      opacity:0;
    }
  }
}