// Checkboxes
//
// $_size should be divisible by 4


@mixin createCheckboxes($_size, $_background, $_border, $_color) {
	input[type="radio"],
	input[type="checkbox"] {
		display: none;

		+ label {
			position: relative;
			display: block;
			min-height: $_size;
			padding-left: $_size + 15px;
			margin: 0;
			@include f(14px,$c-font,400,$_size,initial);
			cursor: pointer;
		}

		+ label	span {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			vertical-align: middle;
			@include size($_size);
			border: $_border;
			background: $_background;
			overflow: hidden;
			cursor: pointer;

			&:before {
				transition: all .3s ease;
				position: absolute;
			}
		}

		&:disabled + label { color: #ddd; }
		&:disabled + label span { background: #ddd; }
	}



	input[type="radio"] + label span {
		border-radius: 50%;
	}

	$_pos: floor($_size * 3 / 8 - 1px);
	input[type="radio"] + label span:before {
		content: "";
		bottom: -$_size;
		left: $_pos;
		display: inline-block;
		@include size(floor($_size / 4));
		border-radius: 50%;
		background-color: $_color;
	}

	input[type="checkbox"] + label span:before {
		content: "";
		bottom: -$_size;
		left: 0;
		display: block;
		@include size(100%);
		background: url(#{$icons}/forms/form-checkbox.png) no-repeat center;
		@include f($_size*.75, $c-theme, 700, $_size*.9);
		text-align: center;
	}
	
	input[type="radio"]:checked + label span:before { bottom: $_pos; }
	input[type="checkbox"]:checked + label span:before { bottom: 0; }
}