@mixin createLoader($_size, $_color: $c-theme) {
	ul {
		position: relative; margin: auto; border-radius: 50%; border: .01em solid rgba(150,150,150,0.1); list-style: none;
		@include size($_size); font-size: $_size;

		li { position: absolute; @include size(.2em); border-radius: 50%; opacity: .85; }

		li:nth-child(1) { top: 0; left: 50%; margin: 0 0 0 -.1em; background: rgba($_color, .3);
		  @include transformOrigin(50% 250%); @include animation(loader-rotate 1.1s linear infinite)
		}
		li:nth-child(2) { top: 50%; right: 0; margin: -.1em 0 0 0; background: rgba($_color, .5);
		  @include transformOrigin(-150% 50%); @include animation(loader-rotate 1.9s linear infinite)
		}
		li:nth-child(3) { bottom: 0; left: 50%; margin: 0 0 0 -.1em; background: rgba($_color, .7);
		  @include transformOrigin(50% -150%); @include animation(loader-rotate 1.5s linear infinite)
		}
		li:nth-child(4) { top: 50%; left: 0; margin: -.1em 0 0 0; background: rgba($_color, .9);
		  @include transformOrigin(250% 50%); @include animation(loader-rotate 1.7s linear infinite)
		}
	}
	
	@include keyframes(loader-rotate) {
	  from {  }
	  to { @include rotate(360deg); }
	}
}