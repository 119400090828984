// Transformicons


// Line transformicons
// tr-lines
@mixin createLines($_size, $_color) {
  &.tr-lines { display: block; font-size: 0;
    .lines,
    .lines:before,
    .lines:after { transition: .4s; display: inline-block; @include size($_size,round($_size / 7.5)); border-radius: ($_size / 15); background: $_color; }

    .lines { position: relative; }
    .lines:before,
    .lines:after { content: ""; position: absolute; left: 0; }
    .lines:before { top: round($_size / 3); }
    .lines:after { top: round($_size / -3); }
  }
}

// tr-lines, tr-arrow-left
@mixin createTrArrowLeft($_size) {
  &.tr-arrow-left {
    .lines:before,
    .lines:after { @include transformOrigin(($_size * .055) center); }
    &.is-active .lines:before,
    &.is-active .lines:after { top: 0; width: ($_size * .6); }
    &.is-active .lines:before { @include rotate(40deg); }
    &.is-active .lines:after { @include rotate(-40deg); }
  }
}

// tr-lines, tr-x
@mixin xTransition() { 
  transition: top 0.3s 0.6s ease, -webkit-transform 0.3s ease;
  transition: top 0.3s 0.6s ease,         transform 0.3s ease;
}
@mixin xLinesTransition() { 
  transition: top 0.3s ease, -webkit-transform 0.3s 0.5s ease;
  transition: top 0.3s ease,         transform 0.3s 0.5s ease;
}
@mixin createTrX() {
  &.tr-x { 
    .lines:before,
    .lines:after { @include transformOrigin(50% 50%); @include xTransition(); }
    &.is-active .lines { transition: background 0.3s 0s ease; background: transparent; }
    &.is-active .lines:before,
    &.is-active .lines:after { @include xLinesTransition(); top: 0; }
    &.is-active .lines:before { @include rotate(45deg); }
    &.is-active .lines:after { @include rotate(-45deg); }
  }
}


@mixin createTransformicon($_kind, $_name, $_size: 14px, $_color: #000) {
  .tr {
    @if $_kind == 'tr-lines' {
      @include createLines($_size, $_color);

      @if $_name == 'tr-arrow-left' { @include createTrArrowLeft($_size); }
      @if $_name == 'tr-x'          { @include createTrX(); }
    }
  }
}
