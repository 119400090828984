.c-scrollback {
  $_offset: 50px;

  position: fixed;
  z-index: 999;
  bottom: $_offset;
  right:  $_offset;
  transition: all .25s ease;

  @include size(50px);
  @include flexAlign();

  background: $c-theme;
  color:$c-white;
  border-radius: 50%;

  cursor: pointer;
  opacity: 0;
  visibility: hidden;

  &:hover {
    transform: scale(1.05);
  }
}