@mixin overlay($color) {
  position: relative;
  z-index:2;
  &::after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    @include size(100%);
    z-index:-1;
    background: $color;
  }
}