.l-socials {
	$social-types: (
					facebook:     $c-facebook,
					instagram:    $c-instagram,
					youtube:      $c-youtube,
					pinterest:    $c-pinterest,
					google-plus:  $c-google-plus,
					linkedin:     $c-linkedin,
					twitter:      $c-twitter,
	);
	$_radius: 50%;
	$_color: $c-white;
	$_size: 22px;
	$_marginRight:15px;

	a {
		margin-right: $_marginRight;
	}

	a i {
		@include size($_size);
		@include f(13px,$_color,300,$_size);
		border-radius: $_radius;
		display: inline-block;
		text-align: center;
		transition: all .25s ease;
		margin-right: $_marginRight/2;
	}

	@each $name, $value in $social-types {
		a.#{$name} {
			i {
				background-color: $value;
				border:1px solid $value;

			}

			&:hover  {
				color:$value;
				i {
					color: $value;
					border-color: $value;
					background: $c-white;
				}
			}
		}
	}

	&--special {
		a {
			margin-right: 0;
		}
	}

}