.p-cart {
	margin-bottom: 50px;

	&__element {
		// background: $c-gray;
		// padding: 30px;
	}

	&__summary{
		margin-top: 50px;
		@include f(16px,$c-black,700);
		span {
			@include f(20px,$c-theme,700);
		}
		.c-btn {
			margin-top: 25px;
		}
	}

	&__item {
		&-form, &-price {
			padding: 40px 0;
			display: inline-block;
			@include mq($screen-sm) {
				padding: 10px 0;
			};
		}

		&-name {
			@include f(16px,$c-black,700,1.5em);
			display: inline-block;
			padding-top: 40px;
		}

		// &-name-container {
		// 	vertical-align: middle;
		// }

		&-price {
			@include f(16px);
			span {
				@include f(20px,$c-theme,700);
			}
		}

		&-form {
			padding-top: 33px;
			@include f(16px);
			.form-standard {
				width: 60px;
				display: inline-block;
				position: relative;
				input[type=number]::-webkit-inner-spin-button {
				    -webkit-appearance: none;
				}
				.up, .down {
					position: absolute;
					right: -25px;
					top:50%;
					cursor: pointer;
					@include f(13px,$c-theme,0,20px);
					text-align: center;
					@include size(20px);
					border-radius: 50%;
					transition: all 0.2s ease;

					&:hover,&:focus {
						background: $c-theme;
						color: $c-white;
					}
				}
				.up {
					transform: translateY(-100%);
					margin-bottom: 2px;
				}
				.down {
					margin-top: 2px;
					// transform: translateY(100%);
				}
			}
		}



	}

}
