@mixin createNumberField() {
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .up, .down {
    position: absolute;
    right: 18px;
    bottom: 0;
    cursor: pointer;
    @include f(13px,$c-theme,0,20px);
    text-align: center;
    @include size(20px);
    transition: all 0.2s ease;

    &:hover,&:focus {
      background: $c-theme;
      color: $c-white;
    }
  }
  .up {
    transform: translateY(-100%);
    margin-bottom: 4px;
  }
  .down {
    margin-top: 2px;
  }
}
