.cookies-wrapper {
	$cookies-background: $c-white;
	$cookies-color: $c-font;
	transition: all .4s ease;
	z-index: $z-index-cookies; //position: $cookies-position; top: $cookies-top; bottom: $cookies-bottom; left: 0; width: 100%;
	background: $cookies-background;
	margin: 0;
	overflow: hidden;
	padding: 10px 0;
	@include f(13px,$cookies-color,400,1.5em,0);
	position: fixed; left: 0; bottom: 0;
	width: 100%;

	.headline {
		font-size: 14px;
		font-weight: 500;
		color: black;
		border: 0;
	}

	p {
		max-height: 100px;
		overflow-y: auto;
		color: #222;
		border-bottom: 1px solid #eee;
		a {
			color: $c-theme;
		}
	}

	box-shadow: 0 0 4px $c-gray;
	&__content {
		position: relative; padding: 10px 0;
		img { position: absolute; top: 0; left: 0; }

	}

	.btn {
		background: $c-theme;
		color: $c-white;
		border-radius: 0;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-right: 10px;
		padding: 5px 20px;

		&.btn-outline {
			border: 1px solid #929292;
			color: $c-font;
			background: white;
			box-shadow: none;
		}
	}
	.btn-cookies {
		transition: all 0.25s ease;
		background: $c-theme;
		//float: right;
		margin: 0;
		position: absolute;
		top:0;
		right:0;
		border: 1px solid transparent; border-radius: 0;
		padding: 0 ;
		@include f(30px,$c-white,500,14px,uppercase); font-style: normal;

		&:hover { background: lighten($c-theme,7%); }
	}

	&.closed { @include scale(.8); opacity: 0; visibility: hidden; }

	@include mq($screen-md) {
		transform: translateY(-36px);
	}

	@include mq($screen-sm) {
		&__content {
			padding-left: 0;
			padding-top:5px;
			img {
				display: none;
			}
		}
	}
}