.p-settings {

	&__tip {

		.c-btn {

		}
	}

	&__nav {
		margin-bottom: 15px;
		//border-radius: 10px;
		padding: 25px 30px;
		background: $c-theme;

		@include mq($screen-sm) {
			padding: 15px;
		}

		li {
			display: block;

			a {
				display: block;
				border-bottom: 1px solid $c-theme;
				padding: 10px 0;
				@include f(15px,$c-white,400,1em);

				@include mq($screen-sm) {
					@include f(12px);
				}

				&:hover, &:focus, &.current {
					//color: $c-theme !important;
				}

				i { margin-left: 3px; }
			}

			ul {
				background: #fff;
				li {
					a {
						font-size: 14px;
						padding: 10px 20px;


					}
				}
			}
		}
	}

	&__tabs-form {

	}

	&__tab-content {
		background: #fdfdfd;
		padding: 30px 20px;
		border: 1px solid lighten($c-theme, 5%);
	}

	&__tabs {
		list-style: none;
		@include flexAlign(0,space-between);
		flex-wrap: wrap;
		li {
			display: inline-block; vertical-align: top;
			margin-bottom:5px;

			a {
				display: inline-block;
				background: #fefefe;
				padding: 10px 12px 8px 12px;
				border: 1px solid #efefef;
				font-size: 20px;
				line-height: 1.2em;

				span { font-size: 13px; }

				&:hover {
					background: $c-theme;
					color: $c-white;
					border-color: lighten($c-theme, 5%);
				}
			}
			&.current a {
				background: $c-theme;
				color: $c-white;
			}
			&.disabled a, &.disabled a:hover {
				background: #fefefe;
				color: lighten($c-font, 20%);
				cursor: not-allowed;
				border-color: #efefef;
			}
		}
	}

	&__ratio {
		background: #6f9f2a;
		color: white;
		padding: 15px 20px;
		margin-bottom: 20px;
		font-size: 14px;

		i {
			float: left;
			display: block;
			font-size: 51px;
			margin-right: 20px;
		}

		.result {
			font-size: 24px;
			font-weight: 600;
		}

		&.warning {
			background: $c-warning;
		}
		&.danger {
			background: $c-danger;
		}
	}

	&__title {
		margin-bottom: 20px;
		@include f(18px,$c-theme,700,1em);
	}

	&__text {
		font-size: 14px;
		color: black;
		font-weight: 600;
		line-height: 1.5em;
		margin-bottom: 20px;
		a { color: $c-theme; }
	}

	&__table {
		width: 100%;
		
		th, td {
			border: 4px solid $c-white;
			padding: 17px 20px;
		}
		th {
			@include f(13px,$c-white,400);
			font-style: italic;
			background: $c-theme;
		}
		td {
			@include f(13px,$c-font);
			background: $c-white;
		}
	}

}