.p-home {

  &__platform-title {
    @include f(36px,$c-theme-alt,700);
  }

  @include mq($screen-sm) {

    &__platform-title {
      @include f(30px);
    }
    &__platform-image {
      img {
        width:90px;
      }
    }
  }
}