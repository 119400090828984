// Center


// aligns children vertically and horizontally
@mixin flexAlign($vertical: center, $horizontal: center) {
	display: flex;

	@if $vertical != 0 {
		align-items: $vertical;
	}
	@if $horizontal != 0 {
		justify-content: $horizontal;
	}
}