.breadcrumbs {
  padding:30px 0;

  &__inner {
    a {
      @include f(14px,#656766);
      &:last-child {
        color: #ababab;
      }
    }
  }
}