@import url('https://fonts.googleapis.com/css?family=Lato:400,900|Roboto:300,400,700&subset=latin-ext');
html, body {
	font-family: $ff-default, sans-serif;
	@include f($default-font-size,$c-font);
  	overflow-x: hidden;
}

.main-content {
  padding: 50px 0;
  @include mq($screen-sidr) {
    padding: 20px 0;
  };
}

@include mq($screen-sidr) {
	.hidden-sidr {
		display: none!important;
	}
}
@include mq($screen-lg) {
	.hidden-lg {
		display: none!important;
	}
}

.visible-sidr {
	display: none!important;

	@include mq($screen-sidr) {
		display: block!important;
	}
}
