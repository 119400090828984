.l-tabs {
    > ul {
      list-style-type: none;
      display: flex;
      margin-bottom:15px;

      > li {
        border: 1px solid $c-theme;
        z-index: 1;
        margin-right: 10px;
        a {
          @include f(17px,#aaa,700);
          display: block;
          padding:15px;
        }

        &.ui-state-active {
          a {
            color:$c-black;
          }
        }
        &.ui-state-focus {
          &:focus {
            outline:none!important;
          }
          a {
            &:focus {
              outline:none!important;
            }
          }
        }
      }
    }
}