// File button


@mixin createFileButton($_height, $_padding, $_background, $_font-size, $_color) {
	.file-button {
		position: relative;

		input[type="file"] {
			position: relative;
			z-index: 2;
			opacity: 0;
			cursor: pointer;
		}
		
		.file-button-cover {
			transition: all .25s ease;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			@include size(100%,$_height);
			padding: 0 $_padding;
			margin: 0;
			@include f($_font-size,$_color,400,$_height,uppercase);
			text-align: center;
			background: $_background;
			cursor: pointer;
		}

		&:hover .file-button-cover { background: lighten($_background, 10%); }

		.MultiFile-list { padding: 0; }
		.MultiFile-list .MultiFile-label {  }
		.MultiFile-list .MultiFile-remove { color: $c-danger; }
	}
}