.l-promo {

  &__item {
    display: flex;
    align-items: center;
    padding: 0 4rem;
    margin-bottom: 2rem;
    @include mq($screen-lg) {
      padding: 0 1rem;
    }
    @include mq($screen-md) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }

  &__icon {
    @include size(50px);
    @include flexAlign();
    margin-right: 2rem;

    @include mq($screen-md) {
      width:100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__content {

  }

  &__name {
    @include f(28px,$c-theme-alt,700,1);
    margin-bottom: .5rem;
    @include mq($screen-md) {
      @include f(25px)
    }
  }

  &__text {
    @include f(18px,$c-font,300);
    @include mq($screen-md) {
      @include f(16px)
    }
  }
}