.option1 {
  .l-newsletter {
    margin-bottom: 30px;
    &__inner {
      background: $c-theme;
      padding: 40px;
      margin-top: 30px;
      span {
        @include f(18px,$c-white,0,45px);
      }
    }

    @include mq($screen-sm) {
      &__inner {
        padding: 20px ;
        span {
          @include f(16px,0,0,normal);
        }
      }
    };
  }
}

.option2 {
  .l-newsletter {
    padding: 50px 0;

    &__inner {
      position: relative;
      //z-index:3;
      background-color: $c-white;
      padding:30px;
      border:5px solid $c-theme-alt;

      &::before, &::after {
        content: '';
        position: absolute;
        background-position: center;
        background-size: cover;
      }

      &::before {
        $w:379px;
        $h:316px;
        $s:1;
        background-image: url(#{$img}/newsletter-2.png);
        @include size($w*$s,$h*$s);
        left:-180px;
        top:-60px;
        z-index:-1;
      }
      &::after {
        $w:382px;
        $h:385px;
        $s:1;
        background-image: url(#{$img}/newsletter-1.png);
        @include size($w*$s,$h*$s);
        top:-100px;
        right:-30px;
        z-index:5;
        @include mq($screen-md) {
          display: none;

        }
      }
    }

    &__title {
      @include f(45px,$c-theme,700);
    }

    &__caption {
      @include f(14px,$c-theme);
      margin-bottom:15px;
    }

    @include mq($screen-md) {
      &__title {
        @include f(25px);
      }
    };
  }
}