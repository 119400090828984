.l-newsletter {
  &__inner {
    background: $c-theme;
    padding: 40px;
    margin-top: 30px;
    @include f(36px,$c-white,300,40px);
  }

  &__text {
    @include mq($screen-sidr) {
      margin-bottom: 1rem;
      text-align:center;
      span {
        @include f(22px);
        display: block;
      }
    }
  }
  @include mq($screen-xlg) {
    &__inner {
      @include f(30px);
    }
  }
  @include mq($screen-lg) {
    &__inner {
      @include f(25px);
    }
  }
  @include mq($screen-sm) {
    &__inner {
      padding: 20px ;
      @include f(16px,0,0,normal);
    }
  };
}