.c-article {

  text-align: center;
  border:5px solid $c-white;
  margin-bottom:15px;

  &__image {
    overflow: hidden;
    display: block;
    @include overlay(rgba($c-black,.3));
    &::after { z-index: 0; transition:all .25s ease; }

    img {
      transition:all .25s ease;
      transform: scale(1.1);
      width:100%;
    }

    &:hover {
      &::after {
        opacity:0;
      }
      img {
        transform: scale(1);
      }
    }
  }

  &__title {
    @include f(16px,$c-black,600);
    display: block;
    margin:15px 0;
  }

  &__text {
    @include f(13px,$c-font,400,1.7em);
    margin-bottom:15px;
  }

  &__link {
    @include f(14px,$c-theme,600);
  }
}