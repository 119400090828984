.p-contact {
  padding-bottom:50px;
  @include mq($screen-sidr) {
    padding-bottom:20px;
  }
  .title__caption {
    margin-bottom:50px;
  }
  &__boxes {
    width:70%;
    display: flex;
    justify-content:space-around;
    margin: 60px auto;
  }

  &__map {
    height:300px;
  }

  &__form-row {
    display: flex;
    justify-content: space-between;
    .item {
      display: inline-block;
      width:49%;
    }
  }
}