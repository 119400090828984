@mixin hasLine($w:30px,$h:2px,$offset:30px, $color: $c-theme, $align: center) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    height:$h;
    width: $w;
    background: $color;
    bottom: -$offset;

    @if $align == center {
      left:50%;
      transform: translateX(-50%);
    }
    @if $align == left {
      left:0;
    }
    @if $align == right {
      right:0;
    }
    @content
  }
}