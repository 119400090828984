.l-footer {

  &__title {
    @include f(24px,$c-theme-alt,700);
    margin-bottom: 1rem;
    @include mq($screen-sm) {
      @include f(20px);
    }
  }

  &__text {
    @include f(20px,$c-font,300);
    @include mq($screen-sm) {
      @include f(17px);
    }
  }

  &__cpr {
    border-top: 1px solid $c-border;
    padding: 2.5rem 0;
    @include f(12px,$c-font,400,0,0,$ff-alt);
    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      li {
        margin-left: 2rem;
        a {
          text-transform: uppercase;
        }
      }
    }
  }
}

.mobile-contact {
  position: fixed;
  z-index: 8999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 36px;
  font-size: 0;
  box-shadow: 0 -1px 3px rgba($c-black,.3);
  background: $c-white;
  display: none;

  a {
    display: inline-block;
    vertical-align: bottom;
    width: 50%;
    @include f(12px,$c-white,500,36px);
    text-align: center;
    background: $c-top-font;

    &:hover, &:focus {
      color: $c-white;
      background: lighten($c-top-font,7%);
    }
  }
  a + a {
    background: $c-theme;
    @include animation(pulse linear .7s alternate infinite);

    &:hover, &:focus {
      background: lighten($c-theme,7%);
    }
  }

  @include mq($screen-md) {
    display: block;
  }
}

@include keyframes(pulse) {
  from { opacity: .7; }
  to   { opacity: 1; }
}
