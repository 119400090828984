@mixin createLine($side:left) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    #{$side}:-2px;
    top:0;
    bottom:0;
    width:4px;
    background-color: #dadada;
  }
  &::after {
    position: absolute;
    content: '';
    #{$side}:-15px;
    top:0;
    @include size(30px);
    border-radius:50%;
    border:4px solid $c-theme-alt;
    background-color: $c-white;
  }
  @include mq($screen-sm) {
    &::before,&::after {
      display: none;
    }
  }
}
.l-cooperation {
  $mod:'.l-cooperation';


  &__icon {
    padding: 1rem 4rem;
    @include mq($screen-sm) {
      width:100%;
      text-align: center;
    }
  }

  &__content {
    flex:0 0 50%;
    padding: 0 3rem 3rem 3rem;
    @include mq($screen-sm) {
      width:100%;
      flex:1;
      text-align: center !important;
      order: 3 !important;
    }
  }


  &__item {
    display: flex;
    justify-content: flex-end;

    &:nth-child(odd) {
      justify-content: flex-start;
      #{$mod}__content {
        order:1;
        text-align: right;
        @include createLine(right);
      }
      #{$mod}__icon {
        order:2;
      }
    }
    &:nth-child(even) {
      #{$mod}__content {
        @include createLine(left);
      }
    }
    &:last-child {
      #{$mod}__content {
        &::before {
          display: none;
        }
      }
    }

    @include mq($screen-sm) {
      flex-wrap: wrap;
    }
  }

  &__title {
    @include f(19px,$c-gray-font,700);
  }

  &__text {
    @include f(15px,$c-font,300);
  }
}