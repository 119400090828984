.c-side {
  //@include mq($screen-sidr) {
  //  display: none;
  //}

  &__title {
    @include f(20px,$c-theme,700,50px);
    padding: 0 20px;
    background-color: $c-white;
    text-align: center;
    -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.03);
    -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.03);
    box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.03);
    margin-bottom:30px;

    @include mq($screen-sidr) {
      display: none;
    }
  }

  &__btn {
    width: 100%;
    @include f(20px,$c-white,700,50px,uppercase);
    background-color: $c-theme-alt;
    margin-bottom: 15px;
  }

  ul { margin: 0; padding: 0; font-size: 0; }
  ul li { position: relative; vertical-align: bottom; display: block; transition: all 0.25s ease;}
  ul li a { position: relative; display: block; cursor: pointer; }

  // I level
  > ul > li > a {
    @include f(18px,$c-font,600,40px);

    &:hover, &:focus {
      span {
        color: $c-white!important;
      }
    }

    span {
      @include f(12px,$c-theme);
      font-style: italic;
      transition: all .25s ease .1s;
    }
  }

  // II level
  > ul > li > ul {
    padding-left: 20px;
  }
  > ul > li > ul > li {
    @include createListStyleImg('li.png', 12px);
    &::after {
      opacity:0;
    }
    &:hover::after {
      opacity:1;
    }
  }
  > ul > li > ul > li > a {
    @include f(15px,$c-font,600,40px);
    &:hover, &:focus {
      background: transparent;
    }
  }
}

.sidr-inner {
  @include mq($screen-sidr) {
    .c-side { padding: 0;
      ul { display: block; }
      ul li a {  }
      ul ul { transition: none; visibility: visible; opacity: 1; position: static; }

      // I level
      > ul {  }
      > ul > li { display: block; margin: 0; border-bottom: 1px solid $c-border; }
      > ul > li > a {
        height: 40px;
        margin: 0;
        padding: 0 15px;
        @include f(16px,$c-font,400,40px,uppercase);

        &:hover, &:focus, &.current {
          background: $c-theme;
          color: $c-white
        }
      }

      // II level
      > ul > li > ul { position: static; padding: 0 10px 10px; background-color: transparent;}
      > ul > li > ul > li { display: block; width: 100%; padding: 0; }
      > ul > li > ul > li > a {
        @include f(14px,$c-font,400,1em); padding: 7px 15px;

        &:hover, &:focus, &.current {
          color: $c-white;
          background: $c-theme;
        }
      }
    }
  }

}