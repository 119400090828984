// config
// --------------------------------------------------

	$img: 	'../img/site';
	$icons: '../img/site/icons';
	$fonts: '../fonts';

	$ff-default: "Roboto";
	$ff-alt: "Lato";
	$ff-fontawesome: 'Font Awesome 5 Free';
	$default-font-size: 16px;


// colors
// --------------------------------------------------

	$c-font: #1a2157; // default font color
	$c-theme:  #c5337e;
	$c-theme-alt:  #891e8b;
	$c-border: #eff1f6;

	$c-white: #fff;
	$c-top-font: #131537;
	$c-black: #000;
	$c-bg: #f6f6f6;
	$c-gray: #c5c5c5;
	$c-gray-dark: #aaa;
	$c-gray-font: #bbb;

	$c-success: hsl(145, 63%, 40%);
	$c-danger:	hsl(356, 100%, 41%);
	$c-warning: hsl(32, 97%, 46%);
	$c-info:    hsl(225, 46, 42%);

	$c-facebook: #385895;
	$c-linkedin: #0077b5;
	$c-twitter: #1da1f2;
	$c-instagram: #ad69b0;
	$c-google-plus: #de4e43;
	$c-youtube: #e62117;
	$c-pinterest: #bd081c;


// media queries
// --------------------------------------------------

	$screen-xxlg: 	1799px;
	$screen-xlg: 	1499px;
	$screen-lg: 	1199px;
	$screen-md: 	991px;
	$screen-sm: 	767px;
	$screen-xs: 	575px;

	$screen-sidr:  $screen-md;


// z-index
// --------------------------------------------------

	$z-index-cookies: 		9000;
	$z-index-sidr-toggle: 8999;
	$z-index-sidr: 				8998;
	$z-index-pace: 				8997;
	$z-index-social: 			1039; // below lightgallery (1040)


// Default forms values
// --------------------------------------------------

	$form-height: 45px;
	$form-border: 1px solid #ddd;
	$form-border-color-focus: #000;
	$form-border-radius: 0;
	$form-padding: 15px;
	$form-font-size: 13px;
	$form-color: $c-font;
	$form-background: $c-white;
	$form-required-color: $c-danger;

	$def-transition: all .25s ease;