.c-loader {
	@include createLoader(75px);
}

.c-loader-black {
	@include createLoader(75px, $c-black);
}

.c-loader-alert {
	@include createLoader(50px);
	margin-bottom: 15px;
}