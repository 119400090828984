.header {
  padding: 1rem 0;
  position: fixed;
  top:0;
  left:0;
  right:0;
  z-index: 9;
  transition: $def-transition;

  &.is-scrolled {
    background-color: $c-white;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3);
    border-bottom: 1px solid $c-border;
    transform: translateY(-35px);
    padding-bottom: 10px;
    @include mq($screen-sidr) {
      transform: none;
      padding-bottom: 1rem;
    }
  }

  @include mq($screen-sidr) {
    background-color: $c-white;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3);
    border-bottom: 1px solid $c-border;
  }

  &__lang {
    margin-bottom: 1rem;
    @include f(12px,$c-gray,900,0,0,$ff-alt);
    text-align: right;
    a {
      &.current {
        color:$c-theme-alt;
      }
      &:not(:first-child) {
        border-left: 2px solid $c-gray;
      }

      padding: 0 .2rem;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__bottom {

  }

  &__logo {

  }

}