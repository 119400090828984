.slider {
  $_nav-offset:20px;
  $_nav-color:$c-black;
  $_content-padding:80px 20px;
  $_title-fs:71px;
  $_title-color:$c-font;
  $_text-fs:24px;
  $_text-color:$c-font;

  $_dots-bg: $c-theme-alt;
  $_dots-size: 10px;
  $_dots-active-bg: $c-theme;

  margin-bottom: -25vw;
  @include mq($screen-lg) {
    margin-bottom: -15vw;
  }
  @include mq($screen-sidr) {
    margin-bottom: 0;
  }
  &__inner {
    position: relative;

    //.prev, .next {
    //  @include flexAlign();
    //  @include f(30px,$_nav-color,700);
    //  @include size(50px);
    //  position: absolute;
    //  top: 50%;
    //  transform: translateY(-50%);
    //  cursor: pointer;
    //  z-index: 3;
    //}
    //.prev {
    //  left: $_nav-offset;
    //}
    //.next {
    //  right: $_nav-offset;
    //}
    //@include mq($screen-lg) {
    //  .prev {
    //    left: $_nav-offset/2;
    //  }
    //  .next {
    //    right: $_nav-offset/2;
    //  }
    //};
    //@include mq($screen-sidr) {
    //  .prev, .next {
    //    display: none;
    //  }
    //};
  }

  .slide {
    background-position: right top ;
    //background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &__image {
      text-align: right;
      @include mq($screen-sidr) {
        margin-top: 75px;
        overflow: hidden;
        img {
          //transform: translateX(80px);
        }
      }
    }
    &__content {
      padding:$_content-padding;
      position: absolute;
      top:90px;
      left:0;
      right:0;
      bottom:0;

      @include mq($screen-sidr) {
        position: relative;
        top:initial;
        left:initial;
        right:initial;
        bottom:initial;
        padding: 20px 0;
        margin-top: -120px;
      }
    }

    &__title {
      @include f($_title-fs,$_title-color,700,1);
    }

    &__caption {
      @include f($_text-fs,$_text-color,300,1.5em);
    }

    @include mq($screen-xlg) {

      &__title {
        @include f(48px);
      }

      &__caption {
        @include f(20px);
      }
    }
  }

  //.owl-dots {
  //  position: absolute;
  //  bottom: 60px;
  //  left: 80px;
  //
  //  .owl-dot span {
  //    transition: $def-transition;
  //    @include size($_dots-size);
  //    border: none;
  //    border-radius: 50%;
  //    margin: 0 5px;
  //    background: $_dots-bg;
  //  }
  //
  //  .owl-dot span:hover,
  //  .owl-dot:hover span,
  //  .owl-dot.active span {
  //    background: $_dots-active-bg;
  //  }
  //
  //  @include mq($screen-sm) {
  //    left:50%;
  //    transform: translateX(-50%);
  //    bottom:10px;
  //  }
  //}
}
