// Sidr components


@mixin createSidr($_width: 300px, $_padding: 15px, $_background: #fff, $_sidr-toggle-size: 50px) {
  .sidr {
    $_headline-height: $_sidr-toggle-size + $_padding;

    display: none;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    z-index: $z-index-sidr;
    @include size($_width,100%);
    padding: $_headline-height $_padding $_padding;
    border-right: 1px solid rgba($c-black,.3);
    box-shadow: 3px 0 12px rgba($c-black,.3);
    background: $_background;

    &:before { 
      $_height: 24px;
      content: "MENU";
      position: absolute;
      top: $_headline-height - $_height + 3px;
      @include f($_height,$c-font,700,$_height,uppercase);
    }

    &.left { left: -$_width; right: auto; &:before { left: $_sidr-toggle-size + 2*$_padding; } }
    &.right { left: auto; right: -$_width; }

    .sidr-inner {
      padding: $_padding 0;
      border-top: 1px solid darken($_background,7%);
      box-shadow: inset 0 1px 0 rgba($c-white,.25);

      &:first-of-type { margin-top: 15px; }
    }
  }
}

@mixin createSidrToggle($_size: 50px, $_background: rgba($c-theme,0.6), $_offset: 15px) {
  .sidr-toggle { 
    transition: opacity .3s;
    display: block;
    position: fixed;
    z-index: $z-index-sidr-toggle;
    top: $_offset;
    @include size($_size);
    padding: ($_size * .46) ($_size * .2);
    background: $_background;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;

    &.left { left: $_offset; }
    &.right { right: $_offset; }

    &.is-ready {
      opacity: 1;
      visibility: visible;
    }

    @include createTransformicon('tr-lines','tr-x',($_size * .6),$c-font);
  }
}