@mixin createGallery() {

  $mod: '.p-product__gallery';
  #{$mod}.vertical {
    #{$mod}-main {
      margin-bottom: 10px;
    }
    #{$mod}-side {
      @include flexAlign(initial, space-between);
      position: relative;
    }
  }

  #{$mod}.horizontal {
    #{$mod}-main {
      display: inline-block;
      vertical-align: top;
      width: 78%;
      margin-bottom: 10px;
      padding-right: 30px;
    }
    #{$mod}-side {
      @include flexAlign(initial, flex-start);
      flex-direction: column;
      &-container {
        display: inline-block;
        width: 20%;
      }
      position: relative;
    }
  }


  &__gallery {
    font-size: 0;
  }

  #{$mod}-img {
    margin: 0;
    margin-bottom:5px;
    overflow: hidden;
    border: 1px solid $c-border;
    img {
      width: 100%;
    }
  }
}

@mixin createQuantity($width:60px) {
  position: relative;
  display: inline-block;
  width: $width;

  input[type="number"] {
    padding-right: 6px;
    -webkit-appearance: none;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      opacity: 0;
      position: relative;
      z-index: 2;
    }
  }
  .up, .down {
    position: absolute;
    right: 0px;
    @include f(10px);
    @include size(30px, 50%);
    cursor: pointer;
    i {
      transform: translateX(100%);
    }
  }
  .up {
    bottom: 50%;
  }
  .down {
    top: 50%;
  }
}