.pace {
	@include userSelect(none);
	-webkit-pointer-events: none;
	pointer-events: none;

	@include mq($screen-sm) {
		display: none;
	}
}

.pace-progress {
	$_color: $c-theme;
	$_height: 2px;
	
	position: fixed;
	z-index: $z-index-pace;
	top: 0;
	right: 100%;
	@include size(100%,$_height);
	background: $_color;
}

.pace-inactive {
	display: none;
}