.c-btn {
	transition: all .25s ease .1s;
	display: inline-block;
	min-width: 165px;
	outline: none;
	box-shadow: none;
	margin: 0;
	border: none;
	padding: 0 15px;
	cursor: pointer;
	@include f(15px,$c-white,700,50px);
	text-align: center;
	background: $c-theme;
	border-radius: 2px;

	&:hover {
		color: $c-white;
		background: lighten($c-theme,7%);
	}

	&--alt {
		background-color: $c-theme-alt;

		&:hover {
			background: lighten($c-theme-alt,7%);
		}
	}

	&--dark {
		background-color: $c-font;

		&:hover {
			background: lighten($c-font,7%);
		}
	}
	&--slider {
		@include f(22px,$c-white,500);
		padding: 0 45px;
	}
	@include mq($screen-xs) {
		@include f(13px)
	}
}
