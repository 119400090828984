.form-standard {
  $_height: 40px;
  $_border: none;
  $_border-color-focus: $c-theme-alt;
  $_border-radius: 0px;
  $_padding: 15px;
  $_font-size: 20px;
  $_color: $c-font;
  $_background: $c-white;
  $_required-color: $c-danger;

  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, $_required-color
  );

  @include createForm($parameters);

  .form-group {

  }
  input {
    font-weight: 300;
  }

  &--newsletter {
    button {
      height: $_height;
      line-height: $_height;
      padding: 0 5px;
    }
    @include mq($screen-sidr) {
      input {
        @include f(15px);
      }
    }
  }
}
.form-contact {
  $_height: 55px;
  $_border: 1px solid $c-border;
  $_border-color-focus: $c-theme-alt;
  $_border-radius: 2px;
  $_padding: 15px;
  $_font-size: 18px;
  $_color: $c-theme-alt;
  $_background: $c-bg;
  $_required-color: $c-danger;

  $parameters: (
          $_height, $_border, $_border-color-focus, $_border-radius, $_padding, $_font-size, $_color, $_background, $_required-color
  );

  @include createForm($parameters);

  .form-group {
    margin-bottom: 1.5rem;
  }
  input,textarea {
    font-weight: 300;
  }
}