.l-portfolio {

  &__item {
    margin-top: 3rem;
  }

  &__image {
    display: inline-block;
    margin:15px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 16px 0px rgba(167,193,246,0.5);
  }

  &__content {
    padding-left: 2rem;
  }

  &__title {
    @include f(28px,$c-theme-alt,700);
    margin-bottom: 1rem;
  }

  &__text {
    @include f(18px,$c-font,300);
    margin-bottom: 2rem;
    ul {
      list-style-type: none;
      li {
        @include hasLine(6px,6px,0,$c-theme-alt,left) {
          border-radius: 100%;
          top:9px;
          left:-2rem;
          bottom:auto;
        }
      }
    }
  }

  &__platform {

  }
}