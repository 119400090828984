.p-login {

  &__text {
    @include f(16px,$c-font,300,2em);
    margin-bottom: 30px;


    @include mq($screen-sm) {
      @include f(13px,0,0,1.5em);
    }
  }

  &__form-wrapper {

  }

  &__narrow {
    padding: 0 80px;


    @include mq($screen-lg) {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 20px;
    @include f(33px,$c-black,500,0,uppercase);
    text-align: center;


    @include mq($screen-sm) {
      @include f(24px);
    }
  }

  &__subtitle {
    margin-bottom: 5px;
    @include f(15px,$c-black,400);
    text-align: center;


    @include mq($screen-sm) {
      margin-top: 15px;
      @include f(12px);
    }
  }

  &__info {
    @include f(13px,$c-font,400);
  }

  &__link {
    color: $c-theme;

    &:hover, &:focus {
      color: $c-theme;
      text-decoration: underline;
    }
  }

  &__button-fb {
    display: block;
    margin-top: 31px;
    @include f(20px,$c-white,700,50px,uppercase);
    text-align: center;
    background: $c-facebook;

    &:hover, &:focus {
      color: $c-white;
      background: lighten($c-facebook,5%);
    }
  }

}
