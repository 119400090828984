.u-full-width { width: 100%!important; }

.u-no-pb { padding-bottom: 0 !important; }
.u-no-pt { padding-top: 0 !important; }
.u-no-pl { padding-left: 0!important; }
.u-no-pr { padding-right: 0!important; }

.u-no-mt { margin-top: 0 !important; }
.u-no-mb { margin-bottom: 0 !important; }
.u-no-ml { margin-left: 0 !important; }
.u-no-mr { margin-right: 0 !important; }

.u-zindex {
  position: relative;
  z-index: 2;
}
.u-bg {
  background-color: $c-bg;
}
.u-white {
  background-color: $c-white;
}

.u-abs-bg {
  background-image:url(#{$img}/absolutes/1.png);
  background-position: left 500px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.u-abs-bg-2 {
  background-image:url(#{$img}/absolutes/2.png);
  background-position: left 0px;
  background-repeat: no-repeat;
  @include mq($screen-md) {
    background-size:43%;
  }
  @include mq($screen-sm) {
    background-size:33%;
  }
}
.u-abs-bg-3 {
  background-image:url(#{$img}/absolutes/3.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  @include mq($screen-md) {
    background-size:43%;
  }
  @include mq($screen-sm) {
    background-size:33%;
  }
}
.u-abs-bg-4 {
  background-image:url(#{$img}/absolutes/4.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  @include mq($screen-md) {
    background-size:43%;
  }
  @include mq($screen-sm) {
    background-size:33%;
  }
}

.js-animation {
  transition: all .5s ease-in-out;
}

.u-animation {
  transform: translateY(20px);
  opacity: 0;
}