@mixin createListStyleImg($path: 'li.png', $_s:15px) {
  position: relative;
  padding-left: calc(10px + #{$_s});

  &::after {
    content: '';
    position: absolute;
    top:14px;
    left:0;
    background-image: url(#{$icons}/#{$path});
    background-position: center;
    background-size: cover;
    @include size($_s);
  }
}